.cal-week-view {
  background-color: var(--ion-background-color);
  border-top: solid 1px var(--ion-color-light);
}
.cal-week-view .cal-day-headers {
  border-color: var(--ion-color-light);
  border-top: 0;
}
.cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: var(--ion-color-light);
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:not(:last-child) {
  border-right-color: initial;
  border-left: solid 1px var(--ion-color-light) !important;
}
.cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: var(--ion-color-light);
}
[dir=rtl] .cal-week-view .cal-day-headers .cal-header:first-child {
  border-left-color: initial;
  border-right-color: var(--ion-color-light);
}
.cal-week-view .cal-day-headers .cal-header:hover,
.cal-week-view .cal-day-headers .cal-drag-over {
  background-color: var(--ion-color-medium);
}
.cal-week-view .cal-day-column {
  border-left-color: var(--ion-color-light-tint);
}
[dir=rtl] .cal-week-view .cal-day-column {
  border-left-color: initial;
  border-right-color: var(--ion-color-light-tint);
}
.cal-week-view .cal-event {
  background-color: var(--ion-color-medium);
  border-color: var(--ion-color-primary);
  color: var(--ion-color-primary);
}
.cal-week-view .cal-all-day-events {
  border-color: var(--ion-color-light);
}
.cal-week-view .cal-header.cal-today {
  background-color: var(--ion-color-tertiary);
  color: var(--ion-color-tertiary-contrast);
}
.cal-week-view .cal-header.cal-weekend span {
  color: var(--ion-color-warning);
}
.cal-week-view .cal-time-events {
  border-color: var(--ion-color-light-tint);

  .cal-event {
    padding-top: 5px;
    line-height: 18px;
    background-color: var(--ion-background-color) !important;
    //border-color: var(--ion-color-primary);
    color: var(--ion-text-color) !important;
    border-width: 2px;
    border-radius: var(--border-radius-base) var(--border-radius-base) var(--border-radius-base) 4px;
  }
}
.cal-week-view .cal-time-events .cal-day-columns:not(.cal-resize-active) .cal-hour-segment:hover {
  background-color: var(--ion-color-medium);;
}
.cal-week-view .cal-hour-odd {
  background-color: var(--ion-color-light);;
}
.cal-week-view .cal-drag-over .cal-hour-segment {
  background-color: var(--ion-color-light-tint);;
}
.cal-week-view .cal-hour:not(:last-child) .cal-hour-segment,
.cal-week-view .cal-hour:last-child :not(:last-child) .cal-hour-segment {
  border-bottom-color: var(--ion-color-light-tint);
}
.cal-week-view .cal-current-time-marker {
  background-color: var(--ion-color-success);
}

.cal-week-view .cal-time {
  padding-top: 0;
  font-size: 14px;
  font-weight: 400;
}

.cal-event-title {
  //color: white;
}

//.cal-time-events {
//  .cal-drag-active {
//    animation-name: wiggle;
//    animation-duration: 500ms;
//    animation-iteration-count: infinite;
//  }
//}
