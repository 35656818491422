///* #### Generated By: http://www.cufonfonts.com #### */
//
@font-face {
  font-family: 'Teko Regular';
  font-style: normal;
  font-weight: normal;
  src: local('Teko Regular'), url('/assets/fonts/Teko-Regular.woff') format('woff');
}


@font-face {
  font-family: 'Teko Light';
  font-style: normal;
  font-weight: normal;
  src: local('Teko Light'), url('/assets/fonts/Teko-Light.woff') format('woff');
}


@font-face {
  font-family: 'Teko Medium';
  font-style: normal;
  font-weight: normal;
  src: local('Teko Medium'), url('/assets/fonts/Teko-Medium.woff') format('woff');
}


.text-header {
  font-family: Teko Regular, Roboto, sans-serif;
  font-size: 32px;
  line-height: 30px;
}

//@font-face {
//  font-family: 'Teko SemiBold';
//  font-style: normal;
//  font-weight: normal;
//  src: local('Teko SemiBold'), url('Teko-SemiBold.woff') format('woff');
//}
//
//
//@font-face {
//  font-family: 'Teko Bold';
//  font-style: normal;
//  font-weight: normal;
//  src: local('Teko Bold'), url('Teko-Bold.woff') format('woff');
//}
