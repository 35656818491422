body {
  --border-radius-base: 15px;
  --border-radius-small: 5px;

  --hover-box-shadow: inset 0 0 100px 100px rgba(var(--ion-color-dark-rgb) 0.3);
}

.fixed {
  position: fixed;
}

.bg-highlight {
  background-color: var(--ion-color-light);
}

.simple-card {
  padding: 16px;
  border-radius: var(--border-radius-base);
  background: var(--ion-color-light);

  opacity: 1;

  transition: opacity 300ms ease-out;
}

.text-bold {
  font-weight: bold;
}

li {
  padding-top: 5px;
  padding-bottom: 5px;
}

ul ul {
  padding-top: 5px;
  padding-inline-start: 25px;
}

.logo {
  display: inline-block;
  background: var(--ion-color-primary);
  //background: linear-gradient(330deg, rgba(34,193,195,1) 0%, rgba(241,253,45,1) 100%);
  //background: linear-gradient(65.5deg, rgb(23, 205, 205) -15.1%, rgb(23, 25, 95) 71.5%);
  background-image: linear-gradient(20deg, #2dc099 0%, #5786a1 100%);


  color: var(--ion-color-primary-contrast);
  border-radius: var(--border-radius-base) var(--border-radius-base) var(--border-radius-base) var(--border-radius-small);
  margin-right: 10px;
  position: relative;

  padding: 10px 10px 4px;


  .logo-text {
    font-family: "Teko Medium", serif;
    font-size: 30px;
    line-height: 20px;
  }
}

.logo-append {
  display: inline-block;
  background: var(--ion-color-medium);
  margin-left: -25px;
  padding: 3px 10px 3px 20px;
  border-radius: 0 var(--border-radius-base) var(--border-radius-base) 0;
  margin-right: 10px;
}

ion-header {
  background-color: var(--ion-toolbar-background);
}


.reorder-container {

  ion-reorder.custom-reorder {
    padding: 5px;
    margin-left: -10px;
    margin-right: 5px;
    opacity: 0;

    @media (hover: none) {
      opacity: 1;
    }

    .custom-reorder-icon {
      color: rgba(var(--ion-text-color-rgb), 0.7);
    }

    &:hover {
      transform: scale(1.1);
    }
  }

  &:hover {
    ion-reorder.custom-reorder {
      opacity: 1;
    }
  }

  &.reorder-selected {
    box-shadow: none;
    app-styled-topic {
      animation-name: wiggle;
      animation-duration: 500ms;
      animation-iteration-count: infinite;
    }
  }
}

.container {
  max-width: 800px;
  margin: auto;
}

ion-button {
  text-transform: none;

  &.button--active {
    --background: rgba(var(--ion-color-dark-rgb), 0.2);
  }
}

ion-item {
  &.button--active {
    --background: rgba(var(--ion-color-dark-rgb), 0.2);
  }
}

ion-badge {
  display: flex;
  align-items: center;
  width: fit-content;
  padding: 5px 7px;
}

ion-popover {
  --backdrop-opacity: 0.1;
  --box-shadow: 0px 0px 6px 3px rgba(var(--ion-color-dark-rgb), 0.2);

  &::part(content) {
    border-radius: 15px;
  }
}

ion-modal.image-viewer {
  --width: 100dvw;
  --height: 100dvh;
}

.low-opacity {
  opacity: 0.5 !important;
}

.pointer {
  cursor: pointer;
}


table {
  font-family: arial, sans-serif;
  border-collapse: collapse;
  width: 100%;
}

td, th {
  //border: 1px solid var(--ion-color-medium);
  text-align: left;
  padding: 8px;
}

tr {
  height: 60px;
  cursor: pointer;
}

tr:nth-child(even) {
  background-color: var(--ion-color-light);
}

td:last-child {
  //text-align: right;
}


.entry-image {
  max-width: 200px;
  image-orientation: from-image;
  cursor: pointer;
  border-radius: var(--border-radius-small);
}


markdown {
  h2 {
    margin-top: 0;
  }

  li {
    padding-top: initial;
    padding-bottom: initial;
  }

  ul ul {
    padding-top: initial;
    padding-inline-start: initial;
  }
}


.border-bottom {
  border-bottom: 1px solid var(--ion-color-medium);
}

.login-card {
  max-width: 280px;
  margin-left: auto;
  margin-right: auto;
}

.input-label-placement-floating.sc-ion-input-md-h .label-text-wrapper.sc-ion-input-md {
  transform: translateY(80%) scale(1);
}
